import { useState,useEffect,useRef } from "react";
import { useTranslation } from "react-i18next";
import Box from '@mui/material/Box';
import { Typography } from "@mui/material";
import { useMediaQuery,useTheme } from '@mui/material';
import ImageCard from './ImageCard';
import solarSolutionImg from './../assets/solarSolution.jpg';
import cableServicesImg from './../assets/cableServices.jpg';
import internetSolutionsImg from './../assets/internetSolutions.jpg';
import phoneServiceImg from './../assets/phoneService.jpg';
import Stepper from "./Stepper";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import BackTop from "./BackTop";
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';

export default function WeblogPostsGallery() {
    const cardContainerRef = useRef(null);
    const [cardContainerWidth,setCardContainerWidth] = useState(0);
    const [currentCard,setCurrentCard] = useState(0);
    const {t,i18n} = useTranslation(['blog']);
    const theme = useTheme();
    const isXS = useMediaQuery(theme.breakpoints.down('xs'));
    const isSM = useMediaQuery(theme.breakpoints.down('sm'));
    const isMD = useMediaQuery(theme.breakpoints.down('md'));
    const isLG = useMediaQuery(theme.breakpoints.down('lg'));
    const isLarger = useMediaQuery(theme.breakpoints.up('lg'));
    
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.down('md'));
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));


    const styles = {
        mainContainer:{
            display:'flex',
            flexDirection:'column',
            width:'75%',
            mx:'auto',
            my:'100px'
        },
        mainTitle: {
            fontStyle: 'normal',
            fontWeight: '700',
            fontSize: {xs:'25px',md:'50px'},
            lineHeight: '67px',  
            color:'#131313',
            textAlign:'center',
            mb:'100px'
        },
        caption: {
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: {xs:'18px',md:'23px'},
            lineHeight: '67px',  
            color:'#131313',
            textAlign:'justify',
            mb:'100px'
        }
    }
    const item = (index) => {
        const styles = {
            paper: {
                width:'100%',
                mx:'auto',
                display:'flex',
                flexDirection:{xs:'column',sm:'column',md:'row'},
                alignItems:'center',
                px:{xs:'10px',sm:'10px',md:'10px'},
                py:{xs:'10px',sm:'10px',md:'10px'},
                gap:'30px',
                my:{xs:'10px',sm:'10px',md:'0px'},    
            },
            contentContainer: {
                width:{xs:'100%',sm:'100%',md:'50%'},
                // mx:'auto',
                display:'flex',
                flexDirection:'column',
                alignItems:'start',
                py:{xs:'10px',sm:'10px',md:'100px'},
                order: {xs:2,sm:2,md:index % 2 === 0 ? 1 : 2},
            },
            title: {
                color:'#000',
                // fontFamily: title.fontFamily,
                fontSize:{xs:'24px',sm:'30px',md:'30px'},
                fontStyle: 'normal',
                fontWeight: '700',
                lineHeight: '42px',
                px:'10px'
            },
            text: {
                color:'#000',
                // fontFamily: text.fontFamily,
                fontSize:{xs:'16px',sm:'22px',md:'22px'},
                fontStyle: 'normal',
                fontWeight: '400',
                lineHeight: '40px',
                py:'30px'
            },
            imgContainer: {
                width:{sm:'100%',md:'50%'},
                order: {xs:2,sm:2,md:index % 2 === 0 ? 2 : 1},
            },
            image: {
                width:'100%',
    
            },
            textContainer: {
                width: '100%',
                // px: '30px'
            },
            buttonContainer: {
                alignSelf:{xs:'center',sm:'end',md:'end'},
                px:'50px',
                my:'30px',                
            }
        }
        const imgUrl = require( `./../../src/assets/Blog/${t(`item${index}.3.image`,{ns:'blog'})}`);
        return (
            <Paper key = {index} sx = {styles.paper}>
                <Box sx = {styles.contentContainer}>
                    <Box sx = {styles.textContainer}>
                        <Typography id = 'title' sx={styles.title} gutterBottom>
                            {t(`item${index}.0.title`,{ns:'blog'})}
                        </Typography>
                        <Typography id = 'caption' sx={styles.text} gutterBottom>
                            {t(`item${index}.1.caption`,{ns:'blog'})}
                        </Typography>
                    </Box>

                    <Box sx = {styles.buttonContainer}>
                        <Button 
                            variant = "contained"
                            color = "error" 
                            endIcon = {i18n.dir() === 'ltr' ? 
                                            <KeyboardDoubleArrowRightIcon /> :
                                            <KeyboardDoubleArrowLeftIcon />
                                      }
                            sx = {{
                                fontSize:{xs:'10px',sm:'14px',md:'14px'},
                                '& .MuiButton-icon': {
                                    px:'10px'
                                },
                            }}
                        >
                            {t('readMore',{ns:'common'})}</Button>
                    </Box>
                </Box>
                <Box sx = {styles.imgContainer}>
                    <img style = {styles.image} src = {imgUrl} />
                </Box>
            </Paper>

        )
    }


    return (
        <Box sx = {styles.mainContainer}>
            <Typography sx = {styles.mainTitle} >{t('title',{ns:'blog'})}</Typography>
            <Typography sx = {styles.caption} >{t('caption',{ns:'blog'})}</Typography>
            {
                Array.from(Array(4)).map((_, index) => {
                    // console.log('index = ', index)
                    // console.log(item(index))
                    return item(index+1)
                })
            }
         </Box>
    )
}