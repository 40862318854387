import { useTranslation } from 'react-i18next';
import SimpleCard from './SimpleCard';

export default function ItemCard({index}) {
    const {t,i18n} = useTranslation(['benefits']);
    return (
        <SimpleCard 
            title = {{
                text: t(`item${index}.0.title`),//"Why Choose Us?",
                color: (index === 2 ) ? '#fff' : '#EE0000',
                fontFamily: 'Poppins',
                fontSize:'50px',
                fontStyle: 'normal',
                fontWeight: '700',
                lineHeight: '67px'
            }} 
            text = {{
                text: t(`item${index}.2.caption`),//"Whether you're looking to switch to solar energy or upgrade your cable services, our sales and marketing company is here to help.",
                color: (index === 2 ) ? '#fff' : '#3E3E3E',
                fontFamily: 'Roboto',
                fontSize:'18px',
                fontStyle: 'normal',
                fontWeight: '400',
                lineHeight: '22px'
            }} 
            subtitle = {{
                text : t(`item${index}.1.subtitle`),//
                color: (index === 2 ) ? '#fff' : '#5C5C5C',
                fontFamily: 'Poppins',
                fontSize:'24px',
                fontStyle: 'normal',
                fontWeight: '700',
                lineHeight: '29px'
            }} 
            logo={null} 
            bgColor={ (index === 2 ) ? '#EE0000' :"F4F4F4"}
            elevation={(index === 2 ) ? 5 : 1}
        />

    )
}