import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

export default function Stepper2({maxSteps,activeStep}) {
    const theme = useTheme();

    return (
        <MobileStepper
            sx = {{
                width:'100%',
                mt:'10px',
                backgroundColor:'transparent',
                '& .MuiMobileStepper-dots': {
                    width:'100%',
                    display:'flex',
                    flexDirection:'row',
                    justifyContent:'center',
                },
                '& .MuiMobileStepper-dot': {
                    backgroundColor:'#fff'
                },
                '& .MuiMobileStepper-dotActive': {
                    backgroundColor:'#EE0000'
                }
            }}
            steps={maxSteps}
            position="static"
            activeStep={activeStep}
        />
    )
}


