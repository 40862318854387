import Box from '@mui/material/Box';
import FacebookIcon from '@mui/icons-material/Facebook';
import XIcon from '@mui/icons-material/X';
import InstagramIcon from '@mui/icons-material/Instagram';

export default function SocialMediaBox() {
    const styles = {
        mainContainer:{
            display:'flex',
            flexDirection:'row',
            alignItems:'center',
            justifyContent:'space-between',
            gap:'10px',
        },
        icons: {
            fontSize:'20px',
        }
    }
    return (
        <Box style = {styles.mainContainer}>
            <FacebookIcon sx = {styles.icons} />
            <XIcon sx = {styles.icons} />
            <InstagramIcon sx = {styles.icons} />
        </Box>                
    )
    
}