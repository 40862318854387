import { useState } from "react";
import { useTranslation } from "react-i18next";
import SimpleCard from "../BenefitCards/SimpleCard";


import { experimentalStyled as styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from "@mui/material/Typography";
import Stepper from "./../Stepper";

import { useMediaQuery,useTheme } from '@mui/material';

import ServiceItemCard from "./ServiceItemCard";
import ServicesHeroImageBox from "./ServicesHeroImageBox";


export default function Services() {
    const {t,i18n} = useTranslation(['services']);
    const [currentItem,setCurrentItem] = useState(1);
    const theme = useTheme();

    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.down('md'));
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
    const styles = {
        mainContainer: {
            width:{sm:'90%',md:'70%'},
            mx:'auto',
            my:'130px',
            px:'10px',
            display:'flex',
            flexDirection:'column',
            alignItems:'center',
        },
        sectionTitle: {
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: '700',
            fontSize: {xs:'16px',md:'20px'},
            lineHeight: '25px',  
            color:'#EE0000',          
        },
        title: {
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: '700',
            fontSize: {xs:'25px',md:'50px'},
            lineHeight: '67px',  
            color:'#131313',
            mt:'60px',
            mb:'100px'      
        },
        galleryContainer: {
            position:'relative',
            display:'flex',
            flexDirection:'column',
            alignItems:'center',
            gap:'30px',
        }
    }
    const itemIndexTable = (isDesktop) ? [1,null,2,3,null,4] :
                           (isTablet)  ? [1,2,3,4] :
                           (isMobile)  ? [1,2,3,4] : [1,2,3,4];
    const handleNext = () => {
        setCurrentItem((prevCurrentItem) => prevCurrentItem + 1)
    }
    const handleBack = () => {
        setCurrentItem((prevCurrentItem) => prevCurrentItem - 1)
    }
    return (
    <Box sx = {styles.mainContainer}>
        <Typography sx = {styles.sectionTitle} >{t('section-title')}</Typography>
        <Typography sx = {styles.title} >{t('title')}</Typography>
        <Box sx = {styles.galleryContainer}>
            <ServicesHeroImageBox />
            <Box sx={{ flexGrow: 1 }}>
                {
                    (isMobile) ? (
                        <>
                            <ServiceItemCard index = {currentItem}/>
                            <Stepper maxSteps = {4} activeStep = {currentItem-1} handleNext = {handleNext} handleBack = {handleBack}/>
                        </>
                    ) :
                    (
                        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 12, md: 12 }}>
                            {Array.from(Array(itemIndexTable.length)).map((_, index) => (
                            <Grid item xs={2} sm={6} md={4} key={index}>
                                {itemIndexTable[index] && <ServiceItemCard index = {itemIndexTable[index]}/>}
                            </Grid>
                            ))}
                        </Grid>
                    )
                }
            </Box>
        </Box>

    </Box>
    );
    }