import { useState,useEffect,useRef } from "react";
import { useTranslation } from "react-i18next";
import Box from '@mui/material/Box';
import { Typography } from "@mui/material";
import { useMediaQuery,useTheme } from '@mui/material';
import ImageCard from './ImageCard';
import solarSolutionImg from './../assets/solarSolution.jpg';
import cableServicesImg from './../assets/cableServices.jpg';
import internetSolutionsImg from './../assets/internetSolutions.jpg';
import phoneServiceImg from './../assets/phoneService.jpg';
import Stepper from "./Stepper";


export default function Expertise() {
    const cardContainerRef = useRef(null);
    const [cardContainerWidth,setCardContainerWidth] = useState(0);
    const [currentCard,setCurrentCard] = useState(0);
    const {t} = useTranslation(['expertise']);
    const theme = useTheme();
    const isXS = useMediaQuery(theme.breakpoints.down('xs'));
    const isSM = useMediaQuery(theme.breakpoints.down('sm'));
    const isMD = useMediaQuery(theme.breakpoints.down('md'));
    const isLG = useMediaQuery(theme.breakpoints.down('lg'));
    const isLarger = useMediaQuery(theme.breakpoints.up('lg'));
    
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.down('md'));
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

    useEffect(() => {
        if (cardContainerRef.current) {
            setCardContainerWidth(cardContainerRef.current.offsetWidth);
        }
    },[]);

    const styles = {
        mainContainer: {
            width:'75%',
            mx:'auto',
            mt:'30px',
            mb:'200px'
        },
        title: {
            textAlign: {xs:'center',sm:'center',md:'left'},
            fontSize:{xs:'20px',sm:'24px',md:'40px'},
            fontWeight:'bold',
            my:{xs:'30px',sm:'40px',md:'60px'},
        },
        cardsContainer: {
            width:{xs:'100%',sm:'100%',md:'90%'},
            display:{md:'flex'},
            flexDirection:'row',
            alignItems:'center',
            gap:'20px',
            justifyContent:'space-between',            
            mx:'auto',
        }
    }

    const cardWidth = isMobile ? cardContainerWidth :
                      isTablet ? 0.9 * cardContainerWidth : 
                      isDesktop ? 0.25 * cardContainerWidth : 0.25 * cardContainerWidth;
    const cardHeight = isMobile ? 1.7 * cardWidth :
                      isTablet ? 1.3 * cardWidth : 
                      isDesktop ? 1.7 * cardWidth : 0.25 * cardWidth;
    const cards = [
        {item:'item1', imgUrl:solarSolutionImg, imgSize: 'cover', imgPos:'center'},
        {item:'item2', imgUrl:cableServicesImg, imgSize: 'cover', imgPos:'center'},
        {item:'item3', imgUrl:internetSolutionsImg, imgSize: 'cover', imgPos:'right'},
        {item:'item4', imgUrl:phoneServiceImg, imgSize: 'cover', imgPos:'center'},
    ]

    const handleNext = () => {
        setCurrentCard((prevCurrentCard) => prevCurrentCard + 1)
    }
    const handleBack = () => {
        setCurrentCard((prevCurrentCard) => prevCurrentCard - 1)
    }
    return (
        <Box sx = {styles.mainContainer}>
            <Typography sx = {styles.title}>{t('title')}</Typography>
                    <Box ref = {cardContainerRef} sx = {styles.cardsContainer}>
                        {
                            (isDesktop) ? cards.map((item,index) => {
                                return (
                                    <Box key = {item.item} sx = {{width:{cardWidth}}}>
                                        <ImageCard
                                            index = {index}
                                            img = {{imgUrl: item.imgUrl, imgSize: item.imgSize, imgPos: item.imgPos}}
                                            title = {t(`${item.item}.0.title`)}
                                            caption={t(`${item.item}.1.caption`)}
                                            cardWidth={cardWidth}
                                            cardHeight = {cardHeight}
                                        />
                                    </Box>
                                )
                            }) :
                                    <Box key = {cards[currentCard].item} sx = {{width:{cardWidth},display:'flex', flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
                                        <ImageCard
                                            index = {currentCard}
                                            img = {{imgUrl: cards[currentCard].imgUrl, imgSize: cards[currentCard].imgSize, imgPos: cards[currentCard].imgPos}}
                                            title = {t(`${cards[currentCard].item}.0.title`)}
                                            caption={t(`${cards[currentCard].item}.1.caption`)}
                                            cardWidth={cardWidth}
                                            cardHeight = {cardHeight}
                                        />
                                        <Stepper maxSteps = {cards.length} activeStep = {currentCard} handleNext = {handleNext} handleBack = {handleBack}/>
                                    </Box>
                        }
                    </Box>

        </Box>
    )
}