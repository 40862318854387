import { useState,useEffect } from "react";
import { useTranslation } from "react-i18next";
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {IR,GB} from 'country-flag-icons/react/3x2';

export default function LanguageSelector() {
    const {i18n} = useTranslation();

    useEffect(() => {
        document.body.dir = i18n.dir();
        
    },[i18n,i18n.language]);
    const languages = [
        {code:'en',name:'English',element:<GB />},
        {code:'fa',name:'Farsi',element:<IR />},
    ]
    const styles = {
        flag: {
            width: {xs:'50px',sm:'60px',md:'50px'}
        },
        flagBox:{
            width: {sm:'50px',md:'40px'}
        }

    }
    const langItems = languages.map((item,index) => {
      return (
          <MenuItem 
            key = {index}
            value={item.code}
            sx = {styles.flag}
          > 
            {item.element}
          </MenuItem>
      )
    })
    const changeLanguage = (lang) => {   
        i18n.changeLanguage(lang);
    }
    return (
        <Box>
        <FormControl 
          variant="standard"
          sx = { styles.flagBox}
        >
          <Select
            id="simple-select"
            label="Language"
            value = {i18n.language}
            defaultValue="fa"
            onChange={(e) => changeLanguage(e.target.value)}
          >
            {langItems}
          </Select>
        </FormControl>
      </Box>
    )
}