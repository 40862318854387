import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { mainMenuItems } from "../../MainMenuItems";
import * as React from 'react';
import Paper from '@mui/material/Paper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Stack from '@mui/material/Stack';



export default function FooterNav() {
    const {t} = useTranslation(['footer','navbar']);
    const styles = {
        mainContainer:{
            mx:'auto',
        },
        paperContainer: {       
            backgroundColor:'transparent',
            color:'#fff',
        },
        menuList:{
            display:{xs:'grid',sm:'flex',md:'flex'},
            flexDirection: {sm:'row',md:'column'},
            alignItems:{md:'center'},
            gridTemplateColumns: 'repeat(3,1fr)',
        },
        menuItem: {
            fontSize:'16px',
            fontWeight:'light'
        },
        title: {
            mt:'30px',
            textAlign:'center',
        },
        link: {
            textDecoration:'none',
            color:'#fff'
        }
    }
    return (
        <Stack  sx = {styles.mainContainer} direction="row" spacing={2}>
            <Paper sx = {styles.paperContainer}>
                <Typography sx = {styles.title}>{t('QuickLinks')}</Typography>
                <MenuList sx = {styles.menuList}>
                    {mainMenuItems.map((item,index) => {                
                        return (
                            <Link style = {styles.link} key={index} to = {"/" + item} >
                                <MenuItem sx = {styles.menuItem} >{t(item,{ns:'navbar'})}</MenuItem>
                            </Link>
                        )
                    })}

                </MenuList>
            </Paper>
        </Stack>
    );
}
