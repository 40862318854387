import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Banner from './Banner';
import heroImageUrl from './../assets/weblogHeroImage.jpg';
import { Trans,useTranslation } from 'react-i18next';
import { useMediaQuery,useTheme } from '@mui/material';


export default function BlogPageHeroBanner() {
    const {t,i18n} = useTranslation(['header']);
    const theme = useTheme();
    const isXS = useMediaQuery(theme.breakpoints.down('xs'));
    const isSM = useMediaQuery(theme.breakpoints.down('sm'));
    const isMD = useMediaQuery(theme.breakpoints.down('md'));

    const bWidth = useMediaQuery(theme.breakpoints.down('md')) ? '100%' : '75%';
    const borderRadii = useMediaQuery(theme.breakpoints.down('md')) ? '0' : '20px';
    const titlePos = isSM ? {x:'20%',y:'40%'}:
                      isMD ? {x:'10%',y:'40%'}:
                             {x:'10%',y:'40%'};

    const titleWidth = useMediaQuery(theme.breakpoints.down('md')) ? '60%' : '30%';
    return (
        <Box sx = {{display:'flex',justifyContent:'center',alignContent:'center',mt:'30px',width:'100vw'}}>
            <Banner 
                bWidth = {bWidth}
                bHeight={'80vh'}
                imgUrl = {heroImageUrl}
                title = {{
                    txt: " ",
                    pos:titlePos, 
                    width:titleWidth, 
                    color:'#fff'
                }} 
                subtitle = {{txt:'',pos:{x:'30%',y:'30%'}}} 
                imgLoc = {'center'} 
                imgSize ={'contain'} 
                borderRadii = {borderRadii}
                gradientDir = {'180deg'}
            />
        </Box>
    )
}