import { useState,useEffect } from 'react';
import UpIcon from '@mui/icons-material/KeyboardArrowUp';
import { red } from '@mui/material/colors';

import Fab from '@mui/material/Fab';

export default function BackTop() {
    const [scrollTop,setScrollTop] = useState(false);
    useEffect(() => {
        setScrollTop(false);
        window.scrollTo({top:0,left:0,behavior:'smooth'});
    },[scrollTop])
    const fabStyle = {
        position: 'fixed',
        bottom: 16,
        right: {xs:'40%',sm:'45%',md:100},
      };
      
      const fabRedStyle = {
        color: 'common.white',
        bgcolor: red[400],
        '&:hover': {
          bgcolor: red[700],
        },
      };
    const fabs = 
        {
          color: 'inherit',
          sx: { ...fabStyle, ...fabRedStyle },
          icon: <UpIcon />,
          label: 'Expand',
        };
    return (
        <Fab onClick = {() => setScrollTop(true)} sx={fabs.sx} color={fabs.color}>
            {fabs.icon}
        </Fab>
    )
}