import axios from 'axios';


const isProduction = process.env.NODE_ENV === 'production';
const baseURL = isProduction
    ?   process.env.REACT_APP_API_BASE_URL_PROD
    :   process.env.REACT_APP_API_BASE_URL_DEV;

const axiosInstance = axios.create({
    baseURL,
});

export default axiosInstance;