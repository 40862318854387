import { HashLink } from 'react-router-hash-link';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';





export default function ImageCard({index,img,title,caption,cardWidth,cardHeight}){
  const styles = {
    mainContainer : {
      position:'relative',
      width: cardWidth,
      height: cardHeight,
      backgroundImage: `url(${img.imgUrl})`,
      backgroundSize: img.imgSize,
      backgroundPosition: img.imgPos,
      backgroundRepeat:'no-repeat',
      borderRadius:'10px',
    },
    overlay: {
      position:'absolute',
      left:'0',
      top:'0',
      width: cardWidth,
      height:cardHeight,
      background: 'linear-gradient(0deg, #000 0%, #00000030 100%)',
      // background: 'linear-gradient(0deg, #13131300 0%, #13131399 100%)',
      borderRadius:'10px'
    },
    textContainer: {
      position: 'absolute',
      left:'30px',      
      top:'60px',
      marginRight:'30px',
      display:'flex',
      flexDirection:'column',
      alignItems:'start',
      justifyContent:'space-between',
      gap:'40px',
      height:'80%',
    },
    title: {
      color:'#fff',
      fontSize: {xs:'20px',sm:'35px',md:'26px'},
      fontWeight:'bold',
    },
    caption: {
      fontSize:{xs:'14px',sm:'25px',md:'18px'},
      color:'#fff',
    }
  }  
  return (
    <Box sx = {styles.mainContainer}>
      <HashLink to = {`/services/#service-${index+1}`}>
        <Box sx = {styles.overlay}>
          <Box sx = {styles.textContainer}>
            <Typography variant='h5' sx = {styles.title}>{title}</Typography>
            <Typography sx = {styles.caption}>{caption}</Typography>
          </Box>
        </Box>
      </HashLink>
    </Box>
  )
}