import { useTranslation } from 'react-i18next';
import SimpleCard2 from './SimpleCard2';


export default function ServiceItemCard({index}) {
    const {t,i18n} = useTranslation(['services']);
    const logo = require( `./../../assets/icons/${t(`item${index}.0.icon`)}`);
    return (
        <SimpleCard2 
            title = {{
                text: t(`item${index}.1.title`),//"Why Choose Us?",
                color: '#464646',
                fontFamily: 'Poppins',
                fontSize:'24px',
                fontStyle: 'normal',
                fontWeight: '700',
                lineHeight: '39px'
            }} 
            text = {{
                text: t(`item${index}.2.caption`),//"Whether you're looking to switch to solar energy or upgrade your cable services, our sales and marketing company is here to help.",
                color: '#787878',
                fontFamily: 'Roboto',
                fontSize:'18px',
                fontStyle: 'normal',
                fontWeight: '400',
                lineHeight: '25px'
            }} 
            subtitle = {{
                text : ' ',
                color: '#787878',
                fontFamily: 'Poppins',
                fontSize:'24px',
                fontStyle: 'normal',
                fontWeight: '700',
                lineHeight: '29px'
            }} 
            logo={logo} 
            bgColor={"#F3F3F3"}
            elevation={1}
            borderRadius = {'20px'}
        />

    )
}