import Container from '@mui/material/Container';
import AboutPageHeroBanner from '../components/AboutPageHeroBanner';
import Expertise from '../components/Expertise';
import Services from '../components/Services/Services';

import Box from '@mui/material/Box';
import BenefitsGallery from '../components/BenefitCards/BenefitsGallery';
import Aboutus from '../components/Aboutus';
import Footer from '../components/Footer/Footer';
import BackTop from '../components/BackTop';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import { useTranslation } from 'react-i18next';
export default function AboutPage() {
    const {t} = useTranslation('about');
    const styles = {
        textContainer: {
            width:'70%',
            textAlign:'justify',
            mx:'auto',
            my:'100px',

        },
        text: {
            fontSize:'20px',
            lineHeight:'40px'
        },
        buttonContact: {
            background: '#000',
            borderRadius: '40px',
            fontSize: {xs:'3.4vw',sm:'16px',md:'16px'},
            padding:{xs:'6px 16px',sm:'10.19px 32px',md:'10.19px 32px'},
            color:'#fff',
            '&:hover': {
                background: 'rgba(0,0,0,0.5)',
                color: '#fff'
            },
            mt:'30px'
        },
    }
    return (
        <Container disableGutters maxWidth = 'none' sx = {{width:'100%',marginTop:'50px'}} >
            <AboutPageHeroBanner />
            <Box sx = {styles.textContainer}>
                <Typography sx = {styles.text}>
                    {t('mainText')}
                </Typography>
                <Button sx = {styles.buttonContact} >{t('contact')}</Button>
            </Box>
            <BackTop />
        </Container>

    )
}