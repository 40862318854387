import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { CardMedia } from '@mui/material';



export default function SimpleCard({title,subtitle,text,logo,bgColor,elevation,borderRadius}) {

    const styles = {
        mainContainer: {
            minWidth: 275,
            minheight:'303.86px',
            margin:'0 auto',
            backgroundColor:bgColor,
            borderRadius: (borderRadius === null) ? 0 : borderRadius,
        },
        title: {
            color:title.color,
            fontFamily: title.fontFamily,
            fontSize:title.fontSize,
            fontStyle: title.fontStyle,
            fontWeight: title.fontWeight,
            lineHeight: title.lineHeight,
        },
        subtitle: {
            color:subtitle.color,
            fontFamily: subtitle.fontFamily,
            fontSize:subtitle.fontSize,
            fontStyle: subtitle.fontStyle,
            fontWeight: subtitle.fontWeight,
            lineHeight: subtitle.lineHeight,
            mb: '30px'
        },
        text: {
            color:text.color,
            fontFamily: text.fontFamily,
            fontSize:text.fontSize,
            fontStyle: text.fontStyle,
            fontWeight: text.fontWeight,
            lineHeight: text.lineHeight,
        }
    }
  return (
    <Card  elevation = {elevation} sx={styles.mainContainer}>
      <CardMedia 
        component="img"
        sx = {{
          width:'60px',
          mx:'30px',
          mt:'30px',

        }}
        image={logo}
      />
      <CardContent>
        <Typography id = 'title' sx={styles.title} gutterBottom>
          {title.text}
        </Typography>
        <Typography id = 'subtitle' sx={styles.subtitle} component="div">
          {subtitle.text}
        </Typography>
        <Typography id = 'text' sx={styles.text}>
          {text.text}
        </Typography>
      </CardContent>
    </Card>
  );
}
