import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography';
import { useTranslation } from "react-i18next";
import { Box } from '@mui/material';



export default function Banner({imgUrl,bWidth,bHeight,title,subtitle,imgLoc,imgSize,url,borderRadii,gradientDir}) {

    const {t,i18n} = useTranslation(['header']);
    const bannerWidth = (bWidth === undefined) ? '100%' : bWidth;
    const bannerHeight = (bHeight === undefined) ? '50vh' : bHeight;
    const titlePos = title.pos;
    const subTitlePos = subtitle.pos;
    const styels = {
        mainContainer: {
            width:bannerWidth,
            backgroundImage:`url(${imgUrl})`,
            backgroundSize:imgSize.toString(),
            backgroundPosition:imgLoc.toString(),
            backgroundRepeat:'no-repeat',
            height:bannerHeight,
            borderRadius: `${borderRadii}`,  
        },
        textContainer: {
            width:'100%',
            height: bannerHeight,
            position:'relative',
        },
        mainTitle: {
            width:title.width,
            fontSize : {md : "3vw", sm : "5vw", xs :"8vw"},
            position:'absolute',
            top:titlePos.y,
            left:titlePos.x,
            cursor:'pointer',
            color:title.color,
        },
        subTitle: {
            fontSize : {md : "1.3vw", sm : "3vw", xs :"4vw"},
            position:'absolute',
            top:subTitlePos.y,
            left:subTitlePos.x,
            fontFamily: (i18n.language === 'fa') ? 'Vazirmatn' : 'Roboto',
            cursor:'pointer',
            color:subtitle.color
         }
    }
    

    return (
        <Paper sx = {styels.mainContainer}>
            <div id = 'overlay'
                 style = {{
                    background: `linear-gradient(${gradientDir}, rgba(0,0,0,1) 15%, rgba(0,0,0,0) 80%)`,
                    borderRadius:borderRadii,
                 }}
            >
                <Box sx = {styels.textContainer} component={'div'}>
                    <a href={url}>
                        <Typography sx = {styels.mainTitle}>
                            {title.txt}
                        </Typography>
                    </a>
                    <Typography sx = {styels.subTitle}>
                        {subtitle.txt}
                    </Typography>
                </Box>
            </div>
        </Paper>
    )
}