import { useTranslation } from "react-i18next";
// import mediaSize from "../../media";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { mainMenuItems } from "../../MainMenuItems";
import * as React from 'react';
import Paper from '@mui/material/Paper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Stack from '@mui/material/Stack';


export default function FooterServiceNav() {
    const {t} = useTranslation(['footer','services']);
    const styles = {
        mainContainer:{
            mx:'auto',
        },
        paperContainer: {       
            backgroundColor:'transparent',
            color:'#fff'     
        },
        menuItem: {
            fontSize:'16px',
            fontWeight:'light'
        },
        menuList:{
            display:{xs:'grid',sm:'flex',md:'flex'},
            flexDirection: {sm:'row',md:'column'},
            alignItems: {md:'center'},
            gridTemplateColumns: 'repeat(2,1fr)',

        },
        menuItem: {
            fontSize:'16px',
            fontWeight:'light'
        },
        title: {
            mt:'30px',
            textAlign:'center',
        }
    }
    return (
        <Stack sx = {styles.mainContainer} direction="row" spacing={2}>
            <Paper sx = {styles.paperContainer}>
                <Typography sx = {styles.title}>{t('Services')}</Typography>
                <MenuList sx = {styles.menuList}>
                    {
                        Array.from(Array(4)).map((_,index) => {
                            return (
                                <MenuItem sx = {styles.menuItem} key = {index}>{t(`item${index+1}.1.title`,{ns:'services'})}</MenuItem>
                            )
                        })
                    }
                </MenuList>
            </Paper>
        </Stack>
    );
}
