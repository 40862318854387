import { ThemeProvider, createTheme } from "@mui/material";
// import { Children } from "react";

import { useTranslation } from "react-i18next";



const Theme = ({children}) => {

    const {i18n} = useTranslation();
    const currentLangauge = i18n.language;
    const currentDirection = i18n.dir();
    const fonts = {
        'en': 'Roboto, Arial, sans-serif',
        'fa': 'Vazirmatn,Arial, sans-serif'
    }
    const theme = createTheme({
        typography: {
            fontFamily: fonts[currentLangauge] || 'Roboto, Arial, snas-serif',
        },
    })
    return (
        <ThemeProvider theme = {theme}>
            {children}
        </ThemeProvider>
    )
}

export default Theme;