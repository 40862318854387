import { useState } from 'react';
import Container from '@mui/material/Container';
import HomePageHeroBanner from '../components/HomePageHeroBanner';
import Expertise from '../components/Expertise';
import Services from '../components/Services/Services';
import axiosInstance from './../axiosConfig';
import Box from '@mui/material/Box';
import BenefitsGallery from '../components/BenefitCards/BenefitsGallery';
import Aboutus from '../components/Aboutus';
import Footer from '../components/Footer/Footer';
import BackTop from '../components/BackTop';
import { Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import { useForm } from 'react-hook-form';
import {reFormatDate,reFormatTime} from './../utlility';

export default function ContactPage() {
    const [successMsg, setSuccessMsg] = useState('');

    const {t,i18n} = useTranslation(['contact']);

    const {
        register,
        formState: {errors},
        clearErrors,
        reset,
        handleSubmit
    } = useForm();

    const styles = {
        mapContainer: {
            display:'flex',
            alignItems:'center',
            justifyContent:'center',
            // border:'1px solid black',
        },
        contentContainer: {
            // border:'1px solid black',
            width:'80%',
            mx:'auto',
            display:'flex',
            flexDirection:{xs:'column',sm:'column',md:'row'},
            alignItems:'top',
            justifyContent:'space-between',
            gap:{xs:'200px',sm:'200px'},
            my:'200px'
        },
        formContainer: {
            width:{xs:'100%',sm:'100%',md:'50%'},
            display:'flex',
            flexDirection:'column',
            alignItems:'center',            
            // border:'1px solid red'
        },
        title: {
            fontSize:{xs:'20px',sm:'22px',md:'26px'},
            fontWeight:'bold',
            my:'30px'
        },
        form: {
            width:'80%',
            display:'flex',
            flexDirection:'column',
            alignItems:'center',
            justifyContent:'space-between',
            gap:'30px'
        },
        textFieldStyle: {
            width:'100%',
            '& .MuiInputLabel-root': {
                fontSize:'16px',
                transformOrigin: i18n.dir() === 'ltr' ? 'top left' : 'top right',
                right:'30px',
            }
        },
        buttonContainer: {
            display:'flex',
            flexDirection:'row',
            alignItems:'center',
            gap:'30px'
        },
        contactInfoBox: {
            // border:'11px solid green',
            display:'flex',
            flexDirection:'column',
            alignItems:'center',
            justifyContent:'top',
            gap:'30px'

        },
        contactInfoTitle: {
            fontSize:{xs:'20px',sm:'22px',md:'26px'},
            fontWeight:'bold',
            my:'30px'
        },
        contactInfoContent: {
            px:'30px',
            display:'flex',
            flexDirection:'column',
            // alignItems:'start',
            justifyContent:'space-between',
            gap:'30px'
        },
        contactInfoText: {
            width:'100%',  
        }
    }

    const onsubmit = (data) => {
        const data1 = {
            fullname: `'${data.fullname.toString()}'`,
            email: `'${data.email.toString()}'`,
            message: `'${data.message.toString()}'`,
            dateofregistration: reFormatDate(new Date()),
            timeofregistration: reFormatTime(new Date()),
        }
        axiosInstance.post('contact.php',data1,
            {
                headers: {'Content-Type' : 'application/json'}
            }
        )
        .then((response) => {
            reset();
            setSuccessMsg('sucess');
        })
        .catch((e) => {
            setSuccessMsg('failed');
            console.log(e);
        })
    }

    const clearMsg = () => {
        clearErrors();
        setSuccessMsg('');
    }

    return (
        <Container disableGutters maxWidth = 'none' sx = {{width:'100%',marginTop:'50px'}} >
            <Box sx ={styles.mapContainer}>
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d347.93123909519585!2d59.58584465173739!3d36.29130628964406!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2s!4v1719841122335!5m2!1sen!2s" 
                        width="100%"
                        height="450" 
                        style={{border:"0"}} 
                        allowfullscreen="" 
                        loading="lazy" 
                        referrerpolicy="no-referrer-when-downgrade">                        
                </iframe>
            </Box>
            <Box sx = {styles.contentContainer}>
                <Box sx = {styles.formContainer}>
                    <Typography sx = {styles.title}>{t('title')}</Typography>
                    <Box
                        component="form"
                        onSubmit= {handleSubmit(onsubmit)}
                        sx = {styles.form}
                    >
                        <TextField 
                                sx = {styles.textFieldStyle} 
                                // name = "fullName" 
                                {...register('fullname',{required:true,minLength: 5})}
                                id = "full_name" 
                                label = {t('fullName')} 
                                variant="filled"
                                onChange={() => clearMsg()}
                        />
                        {
                            (errors.fullname?.type !== undefined) && 
                                (<Typography variant='caption text' color = 'error'>{t('fullnameRequired')}</Typography>)
                        }
                        <TextField 
                                sx = {styles.textFieldStyle}
                                // name = "email"
                                {...register('email',{required:true,minLength: 5, pattern:/[\w]+@[\w]+.[\w]{3}/i})}
                                id = "full_name"
                                label = {t('email')}
                                variant="filled"
                                onChange={() => clearMsg()}
                        />
                        {
                            (errors.email?.type !== undefined) && 
                                (<Typography variant='caption text' color = 'error'>{t('emailRequired')}</Typography>)
                        }
                        <TextField 
                                sx = {styles.textFieldStyle}
                                // name = "message"
                                {...register('message',{required:true,minLength: 5})}
                                id = "full_name"
                                label = {t('message')}
                                variant="filled" 
                                onChange={() => clearMsg()}
                        />
                        {
                            (errors.message?.type !== undefined) && 
                                (<Typography variant='caption text' color = 'error'>{t('msgRequired')}</Typography>)
                        }
                        <Box sx = {styles.buttonContainer}>
                            <Button type = "submit" variant = "contained">{t('send')}</Button>
                            <Button type = "reset" variant = "contained">{t('cancel')}</Button>
                        </Box>
                        <Typography style = {{color: successMsg === 'success' ? "green": 'red'}}>{t(`${successMsg}`)}</Typography> 
                    </Box>
                </Box>
                <Box sx = { styles.contactInfoBox}>
                    <Typography sx = {styles.contactInfoTitle} >{t('contactInfoTitle')}</Typography>
                    <Box sx = {styles.contactInfoContent}>
                        <Typography align = {i18n.dir() === 'rtl' ? "right" : "left"} sx = {styles.contactInfoText}>{t("tel")}: 012365478541</Typography>
                        <Typography align = {i18n.dir() === 'rtl' ? "right" : "left"} sx = {styles.contactInfoText}>{t("email")}: info@KMC.com</Typography>
                        <Typography align = {i18n.dir() === 'rtl' ? "right" : "left"} sx = {styles.contactInfoText}>{t('addressTitle')}: {t('address')}</Typography>
                        <Typography align = {i18n.dir() === 'rtl' ? "right" : "left"} sx = {styles.contactInfoText}>{t("website")}: www.kmc.com</Typography>
                    </Box>
                </Box>

            </Box>

            <BackTop />

        </Container>

    )
}


// 38385483