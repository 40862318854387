import { useTranslation } from 'react-i18next';
import { useMediaQuery,useTheme } from '@mui/material';
import SimpleCard from './SimpleCard';


export default function TitleCard() {
    const {t,i18n} = useTranslation(['benefits']);
    const theme = useTheme();

    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.down('md'));
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
    
    return (
        <SimpleCard 
            title = {{
                text: t('title.0.title'),//"Why Choose Us?",
                color:'#000',
                fontFamily: 'Poppins',
                fontSize: (isMobile) ? '25px': (isTablet) ? '35px' : (isDesktop) ? '40px' : '50px',
                fontStyle: 'normal',
                fontWeight: '700',
                lineHeight: '67px',

            }} 
            text = {{
                text: t('title.1.caption'),//"Whether you're looking to switch to solar energy or upgrade your cable services, our sales and marketing company is here to help.",
                color:'#3E3E3E',
                fontFamily: 'Roboto',
                fontSize:'18px',
                fontStyle: 'normal',
                fontWeight: '400',
                lineHeight: '22px',

            }} 
            subtitle = {{
                text : " ",
                color:'#5C5C5C',
                fontFamily: 'Poppins',
                fontSize:'24px',
                fontStyle: 'normal',
                fontWeight: '700',
                lineHeight: '29px'
            }} 
            logo={null} 
            bgColor={"transparent"}
            elevation={0}
        />

    )
}