import Container from '@mui/material/Container';
import BlogPageHeroBanner from '../components/BlogPageHeroBanner';
import WeblogPostsGallery from '../components/WeblogPostsGallery';
import Services from '../components/Services/Services';

import Box from '@mui/material/Box';
import BenefitsGallery from '../components/BenefitCards/BenefitsGallery';
import Aboutus from '../components/Aboutus';
import Footer from '../components/Footer/Footer';
import BackTop from '../components/BackTop';
export default function BlogPage() {

    return (
        <Container disableGutters maxWidth = 'none' sx = {{width:'100%',marginTop:'50px'}} >
            <BlogPageHeroBanner />

            <WeblogPostsGallery />

            <BackTop />

        </Container>

    )
}