import { useTranslation } from 'react-i18next';
import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';

import {mainMenuItems} from './../MainMenuItems';
import logo from './../assets/logo.png';
import TemporaryDrawer from './TemporaryDrawer';

import { Link } from 'react-router-dom';
import LanguageSelector from './LanguageSelector';

const pages = ['Products', 'Pricing', 'Blog'];
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

function NavBar() {
  const {t,i18n} = useTranslation(['navbar'])
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  
  return (
    <AppBar position="static" sx = {{backgroundColor:'#000',py:'30px'}}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Link to = "/">
            <Box 
              sx = {{
                display:'flex',
                alignItems:'end',
                gap:'15px',
                width: {xs: '100px', sm:'130px', md: '150px'},
                direction: 'ltr'
              }}
            >
              <img src = {logo} style = {{width:'40%'}}/>            
              <Typography sx = {{fontSize: {xs:'17px',sm:'20px',md:'20px'}, fontWeight:'bold', color:'#fff'}}>KMC</Typography>
            </Box>
          </Link>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent:'end',alignItems:'center',gap:'30px'}}>
            {mainMenuItems.map((item) => (
              <Button
                key={item}
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                <Link to = {'/' + item} style = {{textDecoration:'none'}}>
                  <Typography sx = {{
                    color:'#fff',
                    fontFamily: i18n.language === 'fa' ? "Vazirmatn" : "Poppins", 
                    fontSize:'18px'
                    }}
                  >
                    {t(item.toString())}
                  </Typography>
                </Link>
              </Button>
            ))}
            <LanguageSelector />
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' },flexDirection:'row',alignItems:'center',justifyContent:'end'}}>
            <TemporaryDrawer />
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default NavBar;
