import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Container from '@mui/material/Container';
// import mediaSize from './../media';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import BackTop from '../components/BackTop';
import ServiceItemCardLarge from './../components/Services/ServiceItemCardLarge';
import Stepper from './../components/Stepper';
import {useMediaQuery,useTheme } from "@mui/material";
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

export default function ServicesPage() {
    const [currentItem,setCurrentItem] = useState(1);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.between('sm','md'));
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
    const {t} = useTranslation(['services','common']);


    const styles = {
        mainTitle: {
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: '700',
            fontSize: {xs:'25px',md:'50px'},
            lineHeight: '67px',  
            color:'#131313',
            textAlign:'center',
            mb:'100px'
        }
    }
    const item = (index) => {
        const styles = {
            paper: {
                width:'80%',
                mx:'auto',
                display:'flex',
                flexDirection:{xs:'column',sm:'column',md:'row'},
                alignItems:'center',
                px:{xs:'10px',sm:'10px',md:'100px'},
                py:{xs:'10px',sm:'10px',md:'100px'},
                gap:'30px',
                my:{xs:'10px',sm:'10px',md:'100px'},  
                border:'1px solid red'  
            },
            contentContainer: {
                width:{xs:'100%',sm:'100%',md:'50%'},
                // mx:'auto',
                display:'flex',
                flexDirection:'column',
                alignItems:'start',
                py:{xs:'10px',sm:'10px',md:'100px'},
                order: {xs:2,sm:2,md:index % 2 === 0 ? 1 : 2},
                // border:'1px solid red',
            },
            title: {
                color:'#000',
                // fontFamily: title.fontFamily,
                fontSize:{xs:'24px',sm:'30px',md:'30px'},
                fontStyle: 'normal',
                fontWeight: '700',
                lineHeight: '42px',
                px:'10px'
            },
            text: {
                color:'#000',
                // fontFamily: text.fontFamily,
                fontSize:{xs:'16px',sm:'22px',md:'22px'},
                fontStyle: 'normal',
                fontWeight: '400',
                lineHeight: '40px',
                py:'30px'
            },
            imgContainer: {
                width:{sm:'100%',md:'50%'},
                order: {xs:1,sm:1,md:index % 2 === 0 ? 2 : 1}
            },
            image: {
                width:'100%',
    
            },
            textContainer: {
                width: '100%',
                // px: '30px'
            },
            buttonContainer: {
                alignSelf:{xs:'center',sm:'end',md:'end'},
                px:'50px',
                my:'30px'
            }
        }
        const imgUrl = require( `./../../src/assets/services/${t(`item${index}.3.image`,{ns:'services'})}`);
        return (
            <Paper key = {index} sx = {styles.paper} id = {`service-${index}`}>
                <Box sx = {styles.contentContainer}>
                    <Box sx = {styles.textContainer}>
                        <Typography id = 'title' sx={styles.title} gutterBottom>
                            {t(`item${index}.1.title`,{ns:'services'})}
                        </Typography>
                        <Typography id = 'caption' sx={styles.text} gutterBottom>
                            {t(`item${index}.2.caption`,{ns:'services'})}
                        </Typography>
                    </Box>

                    <Box sx = {styles.buttonContainer}>
                        <Button 
                            variant = "contained"
                            color = "error" 
                            endIcon={<ShoppingCartIcon/>}
                            sx = {{
                                '& .MuiButton-icon': {
                                    mx:'10px',
                                }
                            }}
                        >
                            {t('order',{ns:'common'})}</Button>
                    </Box>
                </Box>
                <Box sx = {styles.imgContainer}>
                    <img style = {styles.image} src = {imgUrl} />
                </Box>
            </Paper>

        )
    }

    const imgUrl1 = require( `./../../src/assets/services/${t(`item${1}.3.image`,{ns:'services'})}`);
    const imgUrl2 = require( `./../../src/assets/services/${t(`item${2}.3.image`,{ns:'services'})}`);
    const imgUrl3 = require( `./../../src/assets/services/${t(`item${3}.3.image`,{ns:'services'})}`);
    const imgUrl4 = require( `./../../src/assets/services/${t(`item${4}.3.image`,{ns:'services'})}`);
    // console.log('item = ',item(1))
    return (
        <Container disableGutters maxWidth = 'none' sx = {{width:'100%',marginTop:'50px'}} >
            <Typography sx = {styles.mainTitle} >{t('section-title',{ns:'services'})}</Typography>
            {
                Array.from(Array(4)).map((_, index) => {
                    // console.log('index = ', index)
                    // console.log(item(index))
                    return item(index+1)
                })
            }
            <BackTop />
        </Container>

    )
}