import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import SocialMediaBox from "./SocialMediaBox";

import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';

import * as React from 'react';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import ListItemIcon from '@mui/material/ListItemIcon';
import DraftsIcon from '@mui/icons-material/Drafts';
import SendIcon from '@mui/icons-material/Send';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';

// export default function Contacts(){
//     const {t} = useTranslation(['footer']);
//     return (
//         <Box>
//             <Typography>{t('Contact')}</Typography>
//             <Typography>0123456789</Typography>
//             <Typography>companyname@gmail.com</Typography>
//             <SocialMediaBox />
//         </Box>

//     )
// }



export default function Contacts() {

  const {t} = useTranslation(['footer']);


    const styles = {
        paper: {
            width:{sm:'80%',md:230},
            backgroundColor:'transparent',
            color:'#EDEDED',
            mx:'auto',
        },
        title: {
            mt:'30px',
            textAlign:'center',
        },
        menuList: {
          width:'100%',
          display:{xs:'flex',sm:'grid',md:'flex'},
          gridTemplateColumns: 'repeat(2,1fr)',
          flexDirection:'column',
          alignItems:'center'
        },
        socialMediaTab: {
          gridColumn:'1/3',
          display:'flex',
          alignItems:'center',
          justifyContent:'center',
          mt:{md:'30px'}
        },
        icons: {
            color:'#EDEDED'
        },
        tel:{
          fontFamily:'Roboto',
          fontSize:'16px',
          lineHeight:'19px',
          fontWeight:'300',
          fontStyle:'normal'
        },
        email:{
          fontFamily:'Roboto',
          fontSize:'16px',
          lineHeight:'19px',
          fontWeight:'300',
          fontStyle:'normal'
        }
    }
  return (
    <Paper sx={styles.paper}>
      <Typography sx = {styles.title}>{t('Contact')}</Typography>
      <MenuList sx = {styles.menuList}>
        <MenuItem>
          <ListItemIcon>
            <CallIcon sx = {styles.icons} fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit" sx = {styles.tel} >0123456789</Typography>
        </MenuItem>
        <MenuItem>
          <ListItemIcon>
            <EmailIcon sx = {styles.icons} fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit" sx = {styles.email}>companyname@gmail.com</Typography>
        </MenuItem>
        <MenuItem sx = {styles.socialMediaTab}>
            <SocialMediaBox />
        </MenuItem>
      </MenuList>
      
    </Paper>
  );
}