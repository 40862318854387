import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';

import {mainMenuItems} from './../MainMenuItems';
import { Link } from 'react-router-dom';

import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from '@mui/icons-material/Info';
import CategoryIcon from '@mui/icons-material/Category';
import AnimationIcon from '@mui/icons-material/Animation';
import BookIcon from '@mui/icons-material/Book';
import CallIcon from '@mui/icons-material/Call';
import LanguageIcon from '@mui/icons-material/Language';
import LanguageSelector from './LanguageSelector';
const IconsSet = [HomeIcon,InfoIcon,CategoryIcon,AnimationIcon,BookIcon,CallIcon]


export default function TemporaryDrawer({state}) {
  const [open, setOpen] = React.useState(false);
  const {t} = useTranslation(['navbar']);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const DrawerList = (
    <Box sx={{ width: {xs:250,sm:500}, pt:{sm:'50px'} }} role="presentation" onClick={toggleDrawer(false)}>
      <List>
        {mainMenuItems.map((text, index) => (
          <ListItem key={text} disablePadding sx = {{py:{sm:'10px'}}}>
            <ListItemButton sx = {{gap:{xs:'10px',sm:'30px'}}}>
              <Link to = {'/' + text} style = {{textDecoration:'none',color:"#000",display:'flex',flexDirection:'row',justifyContent:'space-between',width:'100%'}}>
                <ListItemIcon>
                  {React.createElement(IconsSet[index],{sx:{fontSize:{xs:'25px',sm:'40px'}}})}
                </ListItemIcon>
                <ListItemText primary={t(text)} sx = {{'& .MuiListItemText-primary': {
                  fontSize:{xs:'15px',sm:'30px'},
                  width:'100%',
                }
                }}/>              
              </Link>
            </ListItemButton>
          </ListItem>
        ))}
        <Divider />
        <ListItem key={'lang'} disablePadding sx = {{py:{sm:'10px'}}}>
            <ListItemButton sx = {{gap:{xs:'10px',sm:'30px'}}}>
              <ListItemIcon>
                <LanguageIcon />
              </ListItemIcon>
              <LanguageSelector />
            </ListItemButton>
          </ListItem>

      </List>
    </Box>
  );

  return (
    <div>
      <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={toggleDrawer(true)}
              color="inherit"              
            >
              <MenuIcon sx = {{fontSize: {xs:'35px',sm:'45px'}}} />
     </IconButton>
      <Drawer anchor = "right" open={open} onClose={toggleDrawer(false)}>
        {DrawerList}
      </Drawer>
    </div>
  );
}
