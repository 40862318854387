import Container from '@mui/material/Container';
import HomePageHeroBanner from '../components/HomePageHeroBanner';
import Expertise from '../components/Expertise';
import Services from '../components/Services/Services';

import Box from '@mui/material/Box';
import BenefitsGallery from '../components/BenefitCards/BenefitsGallery';
import Aboutus from '../components/Aboutus';
import Footer from '../components/Footer/Footer';
import BackTop from '../components/BackTop';
export default function HomePage() {

    return (
        <Container disableGutters maxWidth = 'none' sx = {{width:'100%',marginTop:'50px'}} >
            <HomePageHeroBanner />
            <Expertise />
            <Box sx = {{backgroundColor:'#F4F4F4',width:'100vw',py:{xs:'20px',sm:'40px',md:'100px'}}}>
                <BenefitsGallery />
            </Box>
            <Box sx = {{backgroundColor:'#EE0000',width:'100vw',py:{xs:'20px',sm:'40px',md:'100px'}}}>
                <Aboutus />
            </Box>
            <Services />

            <BackTop />

        </Container>

    )
}