import { useState } from 'react';
import { experimentalStyled as styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TitleCard from './TitleCard';
import ItemCard from './ItemCard';
import Stepper from '../Stepper';


export default function BenefitsGallery() {
    const [currentIndex,setCurrentIndex] = useState(0);
    const handleNext = () => {
        setCurrentIndex((prevcurrentIndex) => prevcurrentIndex + 1)
    }
    const handleBack = () => {
        setCurrentIndex((prevcurrentIndex) => prevcurrentIndex - 1)
    }
    return (
        <Box sx={{ width:'100%',mx:'auto'}}>
            <Box sx={{ flexGrow: 1,width:{sm:'90%',md:'70%'},mx:'auto',display:{xs:'none',sm:'block',md:'block'}}}>
                <Grid container spacing={{ sm:4, md: 3 }} columns={{ sm: 12, md: 12 }}>
                    {Array.from(Array(6)).map((_, index) => (
                    <Grid item sm = {6} md={4} key={index}>
                        {
                            (index === 0) ? <TitleCard /> : <ItemCard index = {index} />                
                        }
                    </Grid>
                ))}
                </Grid>
            </Box>
            <Box sx = {{display:{xs:'block',sm:'none',md:'none'},width:'80%',mx:'auto'}}>
                <TitleCard />
                <ItemCard index = {currentIndex+1}/>
                <Stepper maxSteps = {5} activeStep = {currentIndex} handleNext = {handleNext} handleBack = {handleBack}/>
            </Box>      
        </Box>

    );
}
