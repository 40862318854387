import Box from '@mui/material/Box';
import FooterCaptionI from './FooterCaptionI';
import FooterLogoBox from './FooterLogoBox';
import FooterNav from './FooterNav';
import FooterServiceNav from './FooterServiceNav';
import Contacts from './Contacts';
import Copyright from './Copyright';
import { useMediaQuery,useTheme } from "@mui/material";


export default function Footer() {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.between('sm','md'));
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
    const styles = {
        mainContainer: {
            width:{sm:'90%',md:'70%'},
            mx:'auto',
            mt:'30px',
            backgroundColor:'#000',
            color:'#fff',
            display:'flex',
            flexDirection:'column',
            alignIems: 'center',
            justfyContents:'space-between'
        },
        itemsContainer: {
            my:{sm:'100px',md:'200px'},
            display:'flex',
            flexDirection:{xs:'column',sm:'column',md:'row'},
            alignItems:'top',
            justifyContent:'space-between',
            gap:{xs:'30px'}
        }
    }
    
    return (
        <Box sx = {styles.mainContainer}>
            {
                (isDesktop) && <FooterCaptionI />
            }
            <Box sx = {styles.itemsContainer}>
                <FooterLogoBox />
                <FooterNav />
                <FooterServiceNav />
                <Contacts />
            </Box>
            <Copyright />
        </Box>
    )
}