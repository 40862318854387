import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {Link} from 'react-router-dom';
import FooterCaptionI from './FooterCaptionI';
import FooterCaptionII from './FooterCaptionII';
import Stepper from '../Stepper';
// import mediaSize from '../../media';
import logo from './../../assets/logo.png';
import { display } from '@mui/system';
import Stepper2 from '../Stepper2';
import { useMediaQuery,useTheme } from "@mui/material";



export default function FooterLogoBox(){
    const [currentCaption,setCurrentCaption] = useState(1);
    const [count, setCount] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(() => {handleNextCaption()},3000)
        return () => clearInterval(intervalId);       
    },[])
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.between('sm','md'));
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

    // return [isMobile,isTablet,isDesktop];
    // const msizes = mediaSize();
    // const isMobile = msizes[0];
    // const isTablet = msizes[1];
    // const isDesktop = msizes[2];

    const handleNextCaption = () => {
        setCurrentCaption(prevCaption => (prevCaption === 1) ? 0 : 1)
    }

    const styles = {
        mainContainer: {
            width:{xs:'100%',sm:'100%',md:'30%'},
            display:'flex',
            flexDirection:'column',
            alignItems:{xs:'center',sm:'center',md:'start'},
            gap:'30px'
        },
        logoBox: {
            // width:{xs:'30%',sm:'30%',md:'40%'},
            display:'flex',
            alignItems:{xs:'center',sm:'center',md:'end'},
            gap:'15px',
            width: {xs: '100px', sm:'300px', md: '150px'},
            direction: 'ltr',
        },
        logo: {
            width:'40%',
        },
        logoText: {
            fontSize: {xs:'17px',sm:'50px',md:'20px'}, 
            fontWeight:'bold', 
            color:'#fff'
        },
        tabletCaptionContainer: {
            width:'80%',
            display:'flex',
            flexDirection:'column',
            alignItems:'center',
            gap:'30px',
        },
        mobiletCaptionContainer: {
            width:'80%',
            display:'flex',
            flexDirection:'column',
            alignItems:'center',
        }
    }
    return (
        <Box sx = {styles.mainContainer}>
            <Box>
                <Link to = "/">
                    <Box 
                        sx = {styles.logoBox}
                    >
                    <img src = {logo} style = {styles.logo}/>            
                    <Typography sx = {styles.logoText}>KMC</Typography>
                    </Box>
                </Link>
            </Box>
            {
                (isDesktop) && <FooterCaptionII />
            }
            {
                (isTablet) && 
                    <Box sx = {styles.tabletCaptionContainer}>
                        <FooterCaptionI />
                        <FooterCaptionII />
                    </Box>
            }
            {
                (isMobile) && 
                    <Box sx = {styles.mobiletCaptionContainer}>
                        {
                            (currentCaption === 1) ? <FooterCaptionI /> : <FooterCaptionII />
                        }
                        <Stepper2 maxSteps = {2} activeStep = {currentCaption}/>
                    </Box>
            }
        </Box>
    )
}