import { useTranslation } from "react-i18next";
// import mediaSize from "../../media";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export default function FooterCaptionII() {
    const {t} = useTranslation(['footer']);
    const styles = {
        text: {
            fontSize:'16px',
            fontWeight:'light',
            textAlign:'justify'

        }
    }
    return (
        <Box>
            <Typography sx = {styles.text} >
                {t('caption2')}
            </Typography>
        </Box>

    )
}