import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export default function Copyright(){
    const {t} = useTranslation(['footer'])
    const styles = {
        mainContainer:{
            mt:'30px',
            textAlign:'center',
            width:{xs:'60%',md:'100%'},
            mx:'auto',
            mb:'30px'
        },
        text:{
            fontSize:{xs:'12px',md:'16px'},
            LineHeight:'24px',
        }
    }
    return (
        <Box sx = {styles.mainContainer}>
            <Typography sx = {styles.text}>{t('copyright')}</Typography>
        </Box>
    )
}
// © 2023 Company Name  -  developed by Roots Digital Marketing