import Box from '@mui/material/Box'
import solarImageUrl from './../../assets/solar.png';
import { useMediaQuery,useTheme } from '@mui/material';

export default function ServicesHeroImageBox() {     
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.down('md'));
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
    
    const styles = {
        mainContainer: isDesktop ? {
            position:'absolute',
            left:'50%',
            top:'50%',
            transform:'translate(-50%,-50%)',
            width:'30%',
        } :
        {
            width:'80%',
        },
        image: {
            width:'100%'
        }
    }
    return (
        <Box sx = {styles.mainContainer}>
            <img style = {styles.image} src = {solarImageUrl} />
        </Box>
    )
}