import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";

import aboutUsImgUrl from './../assets/aboutus.jpg';

export default function Aboutus() {
    const {t,i18n} = useTranslation(['about']);

    const styles = {
        mainContainer: {
            display:'flex',
            flexDirection:{xs:'column',sm:'column',md:'row'},
            alignItems:'center',
            justifyContent:'space-between',
            gap:{xs:'10px',sm:'20px',md:'100px'},
            width:{xs:'80%',sm:'90%',md:'70%'},
            mx: 'auto',
            py:{xs:'30px',sm:'',md:'100px'}
        },
        textContainer: {
            order:{xs:2,sm:2,md:1},
            width:{xs:'90%',sm:'90%',md:'50%'},
            display:'flex',
            flexDirection:'column',
            alignItems:'start',
            justifyContent:'space-between',
            gap:{xs:'10px',sm:'30px',md:'30px'},

        },
        title: {
            width:'100%',
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: '700',
            fontSize: {xs:'20px',sm:'40px',md:'50px'},
            lineHeight: '85px',
            textAlign: {xs:'center',sm:(i18n.dir() === 'rtl') ? 'right' : 'left',md:(i18n.dir() === 'rtl') ? 'right' : 'left'},
            color: '#FFFFFF',
        },
        context: {
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: {xs:'16px',sm:'20px',md:'18px'},
            lineHeight: {xs:'30px',sm:'40px',md:'30px'},
            textAlign: {xs:'justified',sm:(i18n.dir() === 'rtl') ? 'right' : 'left',md:(i18n.dir() === 'rtl') ? 'right' : 'left'},
            color: '#FFFFFF',
        },
        buttonsContainer: {
            width:{xs:'100%',sm:'60%',md:'70%'},
            display:'flex',
            flexDirection:'row',
            alignItems:'center',
            justifyContent:'space-between',
            gap:{xs:'0vw',sm:'20px',md:'10px'},
        },
        buttonReadMore: {
            background: '#FFFFFF',
            borderRadius: '40px',
            fontSize: {xs:'3.4vw',sm:'16px',md:'16px'},
            padding:{xs:'6px 16px',sm:'10.19px 32px',md:'10.19px 32px'},
            color:'#000',
            '&:hover': {
                    background: 'rgba(255,255,255,0.5)',
                    color:'#fff'
            }
        },
        buttonContact: {
            background: '#000',
            borderRadius: '40px',
            fontSize: {xs:'3.4vw',sm:'16px',md:'16px'},
            padding:{xs:'6px 16px',sm:'10.19px 32px',md:'10.19px 32px'},
            color:'#fff',
            '&:hover': {
                background: 'rgba(0,0,0,0.5)',
                color: '#fff'
            }
        },
        imageContainer: {
            order:{xs:1,sm:1,md:2},
            width:{xs:'100%',sm:'90%',md:'50%'},
        },
        image: {
            width:'100%',
        }
    }
    return (
        <Box sx = {styles.mainContainer}>
            <Box sx = {styles.textContainer}>
                <Typography sx = {styles.title}>{t('title')}</Typography>
                <Typography sx = {styles.context}>{t('context')}</Typography>
                <Box sx = {styles.buttonsContainer}>
                    <Link to = "/about">
                        <Button sx = {styles.buttonReadMore}  >{t('readmore')}</Button>
                    </Link>
                    <Link to = "/contact">
                        <Button sx = {styles.buttonContact} >{t('contact')}</Button>                    
                    </Link>
                </Box>
            </Box>
            <Box sx = {styles.imageContainer}>
                <img style = {styles.image} src = {aboutUsImgUrl} />
            </Box>
        </Box>
    )
}